import {fadeIn} from '@pernod-ricard-global-cms/jsanimations';

export default function siteHeaderJs(options = {}) {
	try {
		// CSS for site-header should usually be included in critical.scss
		const {block} = options;
		fadeIn(block);

		const documentBody = document.body;
		const htmlBody = document.documentElement;
		const navContainer = document.querySelector('.site-nav');
		const hamburger = block.querySelector(
			'.site-nav__interactions__hamburger'
		);
		const hamburgerBars = block.querySelectorAll(
			'.site-nav__interactions__hamburger__bar'
		);
		const primaryNav = document.querySelector('.site-nav__primary');
		const secondaryNav = document.querySelector('.site-nav__secondary');
		const subnav = document.querySelectorAll('.site-nav > ul > li');
		const closeSub = document.querySelectorAll(
			'.site-nav__primary__sub__close'
		);
		const dropdown = document.querySelector(
			'.site-nav__product-switch__dropdown'
		);
		const ctbButton = document.querySelector('.site-nav__secondary__shop');

		const closeSubNav = () => {
			if (dropdown) {
				dropdown.classList.add('closed');
			}
			documentBody.classList.remove('subnav-active');
			htmlBody.classList.remove('subnav-active');
			document
				.querySelectorAll('.site-nav__primary__sub.active')
				.forEach((item) => {
					item.classList.remove('active');
				});
			document.querySelectorAll('.site-nav .active').forEach((item) => {
				item.classList.remove('active');
			});
			navContainer.classList.remove('active');
			documentBody.classList.remove('subnav-active');
			htmlBody.classList.remove('subnav-active');
		};

		const openSubNav = (element) => {
			element.classList.add('active');
			element
				.querySelector('.site-nav__primary__sub')
				.classList.add('active');
			documentBody.classList.add('subnav-active');
			if (window.innerWidth >= 1024) {
				element.addEventListener(
					'mouseleave',
					function (ev) {
						var goingto = ev.relatedTarget || ev.toElement;
						if (
							!goingto.classList.contains(
								'site-nav__secondary__inner'
							) &&
							!goingto.classList.contains('site-nav__secondary')
						) {
							// set timeout helps with sub nav behavior while secondary nav exists on the page
							closeSubNav();
						}
					},
					false
				);
			} else {
				navContainer.classList.add('active');
				documentBody.classList.add('mobile-menu-active');
				htmlBody.classList.add('mobile-menu-active');
				navContainer
					.querySelectorAll('.site-nav__primary__sub')
					.forEach((sub) => {
						sub.style.height = primaryNav.offsetHeight + 'px';
					});
			}
		};

		closeSub.forEach((button) => {
			button.addEventListener('click', function (event) {
				documentBody.classList.remove('subnav-active');
				htmlBody.classList.remove('subnav-active');
				setTimeout(() => {
					document
						.querySelectorAll('.site-nav__primary__sub.active')
						.forEach((item) => {
							item.classList.remove('active');
					});
				}, 850);
			});
		});

		subnav.forEach((li) => {
			if (window.innerWidth < 1024) {
				if (li.classList.contains('has-children')) {
					li.querySelector('a').addEventListener(
						'click',
						function (event) {
							event.preventDefault();
							li.click();
						}
					);
					li.addEventListener('click', function (event) {
						if (event.target == li) {
							event.preventDefault();
							openSubNav(event.target);
						}
					});
				}
			}

			if (
				li.classList.contains('has-children') &&
				window.innerWidth >= 1024
			) {
				li.addEventListener('mouseenter', function (event) {
					if (event.target.querySelector('.site-nav__primary__sub')) {
						if (
							!event.target
								.querySelector('.site-nav__primary__sub')
								.classList.contains('active')
						) {
							closeSubNav();
							if (event.target.classList.contains('has-children'))
								openSubNav(event.target);
						}
					}
				});
			}
		});

		hamburger.addEventListener('click', function () {
			hamburger.classList.toggle('active');
			hamburgerBars.forEach((item) => {
				item.classList.toggle('active');
			});
			navContainer.classList.toggle('active');
			documentBody.classList.toggle('mobile-menu-active');
			htmlBody.classList.toggle('mobile-menu-active');
			primaryNav.classList.toggle('mobile');
			if (!navContainer.classList.contains('active')) {
				closeSubNav();
			}
		});

		//This closes the navigation when the page has been loaded from the (back forward) bfcache.
		window.addEventListener('pageshow', (event) => {
			if (event.persisted) {
				hamburger.classList.remove('active');
				navContainer.classList.remove('active');
				hamburgerBars.forEach((item) => {
					item.classList.remove('active');
				});
				documentBody.classList.remove('mobile-menu-active');
				htmlBody.classList.remove('mobile-menu-active');
				primaryNav.classList.remove('mobile');
			} else {
				//silence is golden
			}
		});
	} catch (error) {
		console.error(error);
	}
}
