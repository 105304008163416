export default function welcomeMatJS(
	options = {sessionItemName: 'hasVisitedbeefeatergin'}
) {
	const {sessionItemName} = options;
	try {
		const welcomeMatModal = document.querySelector('.modal--welcome-mat');
		if (welcomeMatModal) {
			const welcomeMatExpiry =
				window.localStorage.getItem('welcomeMatExpiry');
			const isFirstVisit = !JSON.parse(
				window.sessionStorage.getItem(sessionItemName)
			);
			const today = new Date();
			const expiry = welcomeMatExpiry
				? new Date(Number(welcomeMatExpiry))
				: null;

			const welcomeMatGroups =
				welcomeMatModal.querySelectorAll('.pr-cdb-form-group');

			Array.from(welcomeMatGroups).forEach((group) => {
				const inputs = group.querySelectorAll('.pr-cdb-form-control');

				if (inputs.length) {
					Array.from(inputs).forEach((input) => {
						input.name = `${input.name}-welcome-mat`;
					});
				}
			});

			if (!expiry || today > expiry) {
				if (expiry) {
					window.localStorage.removeItem('welcomeMatExpiry');
				}

				if (isFirstVisit) {
					window.sessionStorage.setItem(sessionItemName, true);
					const modalCloseButtons =
						welcomeMatModal.querySelectorAll('.modal__close');

					const welcomeMatModalContainer =
						welcomeMatModal.querySelector('.modal__container');
					welcomeMatModal.classList.add('modal--visible');
					welcomeMatModalContainer.classList.add('fadeInRight');

					document.body.classList.add('modal-active');

					modalCloseButtons.forEach((button) => {
						button.addEventListener('click', () => {
							window.localStorage.setItem(
								'welcomeMatExpiry',
								today.setDate(today.getDate() + 30)
							);
						});
					});
				}
			}
		} else {
			window.sessionStorage.setItem(sessionItemName, true);
		}
	} catch (error) {
		console.log(error);
	}
}
