import {isEmailValid} from '@pernod-ricard-global-cms/jsutils';

function processOptIns(form) {
	const optIns = [];
	const optInFields = form.querySelectorAll('[pr-cdb-form-terms]');
	optInFields.forEach((optInField) => {
		const checkboxElement = optInField.querySelector(
			'input[type=checkbox]'
		);
		if (!checkboxElement || !checkboxElement.checked) {
			return;
		}

		const brandId = checkboxElement.getAttribute('pr-cdb-brandid');

		optIns.push(
			brandId
				? {
						optInStatus: 1,
						brandId,
						legalConsent: optInField.textContent
							.trim()
							.replace(/[\r\n\t]+/g, ' ')
							.replace(/[ ]{2,}/g, ' ')
				  }
				: {
						optInStatus: 1,
						legalConsent: optInField.textContent
							.trim()
							.replace(/[\r\n\t]+/g, ' ')
							.replace(/[ ]{2,}/g, ' ')
				  }
		);
	});

	return optIns;
}

const getParentElement = (field) => {
	let parentElement;
	if (
		field.parentElement.classList.contains('pr-cdb-form-dob') ||
		field.parentElement.classList.contains('pr-cdb-form-checkbox-container')
	) {
		parentElement = field.parentElement.parentElement;
	} else {
		parentElement = field.parentElement;
	}

	return parentElement;
};

export default function cdbFormJs() {
	try {
		const allFormContainers = document.querySelectorAll(
			'.newsletter__container'
		);

		let currentInput = null;

		allFormContainers.forEach((container) => {
			let percentage;
			const inputContainers =
				container.querySelectorAll('.pr-cdb-form-group');
			const inputs = container.querySelectorAll('.pr-cdb-form-control');
			const indicator = container.querySelector(
				'.newsletter__progress-indicator'
			);
			const indicatorContainer = container.querySelector(
				'.newsletter__progress-container'
			);
			const nextButton = container.querySelector(
				'.newsletter__submit-next'
			);
			const submitButton = container.querySelector(
				'.newsletter__submit-button'
			);
			const backButton = container.querySelector(
				'.newsletter__submit-button--back'
			);
			const form = container.querySelector('form');
			const messageContainer = container.querySelector(
				'.newsletter__message'
			);
			const message = container.querySelector(
				'.newsletter__message-form'
			);
			const fieldMessageContainer = container.querySelector(
				'.newsletter__message-fields'
			);
			const emailMessageContainer = container.querySelector(
				'.newsletter__message-email'
			);
			const dateMessageContainer = container.querySelector(
				'.newsletter__message-date'
			);
			const birthdayMessageContainer = container.querySelector(
				'.newsletter__message-birthday'
			);
			const description = container.querySelector(
				'.newsletter__description'
			);
			const submitContainer = container.querySelector(
				'.newsletter__submit-container'
			);
			const spinner = container.querySelector('.newsletter__spinner');
			const formContainer = container.querySelector('.pr-cdb-container');

			// commenting resetContainer and button as I think it might be asked for in the future
			// const resetContainer = container.querySelector(
			// 	'.newsletter__reset-container'
			// );
			// const resetButton = container.querySelector(
			// 	'.newsletter__reset-button'
			// );
			const progressDescription = container.querySelector(
				'.newsletter__progress-description'
			);
			const progressSuccess = container.querySelector(
				'.newsletter__progress-success'
			);

			const clearError = (input = null) => {
				if (input) {
					const parentElement = getParentElement(input);
					parentElement.classList.remove('pr-cdb-form-group--error');
				} else {
					inputContainers.forEach((thisContainer) => {
						thisContainer.classList.remove(
							'pr-cdb-form-group--error'
						);
					});
				}

				messageContainer.classList.remove('newsletter__message--show');
				message.classList.remove('newsletter__message--show');
				fieldMessageContainer.classList.remove(
					'newsletter__message--show'
				);
				emailMessageContainer.classList.remove(
					'newsletter__message--show'
				);
				dateMessageContainer.classList.remove(
					'newsletter__message--show'
				);
				birthdayMessageContainer.classList.remove(
					'newsletter__message--show'
				);
			};

			const showError = (input, errorContainer) => {
				clearError();
				const parentElement = getParentElement(input);
				parentElement.classList.add('pr-cdb-form-group--error');
				parentElement.classList.remove('pr-cdb-form-group--active');

				messageContainer.classList.add('newsletter__message--show');
				errorContainer.classList.add('newsletter__message--show');
			};

			const getErrorContainer = (input) => {
				let errorContainer = null;
				if (input.type === 'email' && input.value) {
					errorContainer = emailMessageContainer;
				} else if (
					input.classList.contains('pr-cdb-form-control__day') ||
					input.classList.contains('pr-cdb-form-control__month') ||
					input.classList.contains('pr-cdb-form-control__year')
				) {
					errorContainer = dateMessageContainer;
				} else {
					errorContainer = fieldMessageContainer;
				}

				return errorContainer;
			};

			const goBackToErrorField = (index) => {
				const errorContainer = getErrorContainer(inputs[index]);
				form.scroll({
					left: inputs[index].offsetLeft - form.offsetLeft,
					behavior: 'smooth'
				});

				setTimeout(() => {
					inputs[index].focus();
					showError(inputs[index], errorContainer);
				}, 200);
			};

			// 1. move all elements in a terms field to be on the same level so we can use css grid
			// 2. rename terms label and terms input name because when you have more than one terms
			// and you click the second terms label, it triggers the first
			// 3. add modal trigger to terms and conditions href
			const allTerms = container.querySelectorAll(
				'.pr-cdb-form-group__terms'
			);
			allTerms.forEach((term, index) => {
				const label = term.querySelector('label');
				const termsInput = term.querySelector('input');
				const link = term.querySelector('a');
				const newLabel = document.createElement('div');
				newLabel.classList.add('pr-cdb-form-checkbox-label');

				termsInput.name = `${termsInput.name}-${index}`;

				const labelChildren = label.childNodes;
				const smallPrint = term.querySelector(
					'.pr-cdb-form-smallprint'
				);

				const checkboxContainer = document.createElement('label');
				checkboxContainer.classList.add(
					'pr-cdb-form-checkbox-container'
				);

				Array.from(labelChildren).forEach((child) => {
					if (child.tagName === 'INPUT') {
						checkboxContainer.appendChild(child);
					} else {
						newLabel.appendChild(child);
					}
				});

				checkboxContainer.appendChild(smallPrint);

				label.remove();

				term.appendChild(newLabel);
				term.appendChild(checkboxContainer);

				// add modal trigger
				if (link) {
					link.classList.add('modal__trigger');
					link.setAttribute(
						'data-modal-name',
						'terms-and-conditions'
					);

					link.addEventListener('click', (event) => {
						event.preventDefault();
					});
				}
			});

			// commenting as I feel like this function will be needed in the future
			// eslint-disable-next-line no-unused-vars
			// const clearForm = () => {
			// 	form.reset();
			// 	nextButton.style.display = 'inline-block';
			// 	submitButton.style.display = 'none';
			// 	backButton.style.display = 'none';

			// 	message.innerHTML = '';
			// 	message.classList.remove( 'newsletter__message--success' );
			// 	message.classList.remove( 'newsletter__message--error' );
			// 	message.classList.remove( 'newsletter__message--show' );
			// 	messageContainer.classList.remove(
			// 		'newsletter__message--show'
			// 	);

			// 	// commenting resetContainer as I think it might be asked for in the future
			// 	// resetContainer.classList.remove( 'show' );
			// 	progressSuccess.classList.remove( 'show' );
			// 	progressDescription.classList.remove( 'hide' );
			// 	formContainer.classList.remove( 'hide' );
			// 	description.classList.remove( 'hide' );
			// 	submitContainer.classList.remove( 'hide' );
			// 	indicator.style.width = '0px';
			// 	indicator.style.position = 'relative';
			// 	indicator.style.right = 'initial';
			// 	indicator.style.marginLeft = 'initial';

			// 	currentInput = null;

			// 	form.scroll( {
			// 		left: 0,
			// 	} );
			// };

			function onRequestStateChange(event) {
				if (event.target.readyState === XMLHttpRequest.DONE) {
					// remove the "we're sending" class
					form.classList.remove('pr-cdb-submitting');
					// resetErrorState();

					const {status} = event.target;
					let response;

					try {
						response = JSON.parse(event.target.response);

						if (status !== 200) {
							submitButton.style.display = 'none';
							nextButton.style.display = 'inline-block';

							const errorField = response.data.field;
							const errorMessage = response.message.toLowerCase();
							let field = null;

							if (errorField === 'birthday') {
								field = container.querySelector(
									'input.pr-cdb-form-control__day'
								);
							} else {
								field = container.querySelector(
									`input[name="${errorField}"]`
								);
							}

							if (errorField) {
								const parentElement = getParentElement(field);
								field.focus();
								parentElement.classList.add(
									'pr-cdb-form-group--error'
								);
							}

							if (errorField === 'birthday') {
								if (errorMessage.includes('too young')) {
									birthdayMessageContainer.classList.add(
										'newsletter__message--show'
									);
								} else {
									dateMessageContainer.classList.add(
										'newsletter__message--show'
									);
								}
							} else {
								message.innerHTML = errorMessage;
								message.classList.add(
									'newsletter__message--error'
								);
								message.classList.add(
									'newsletter__message--show'
								);
							}
							messageContainer.classList.add(
								'newsletter__message--show'
							);
						} else {
							const {thankYouMessage} = response;

							message.innerHTML = thankYouMessage;
							messageContainer.classList.add(
								'newsletter__message--show'
							);
							message.classList.add(
								'newsletter__message--success'
							);
							message.classList.add('newsletter__message--show');

							// commenting resetContainer as I think it might be asked for in the future
							// resetContainer.classList.add( 'show' );
							formContainer.classList.add('hide');
							description.classList.add('hide');
							submitContainer.classList.add('hide');
							progressDescription.classList.add('hide');
							progressSuccess.classList.add('show');
							spinner.classList.remove('active');

							indicator.style.width = '32px';
							indicator.style.position = 'absolute';
							indicator.style.right = '0';
							indicator.style.marginLeft = 'auto';
						}
					} catch (error) {
						console.log(error);
					}
				}
			}

			const submitForm = () => {
				let formIsValid = true;
				let earliestError = null;
				inputContainers.forEach((thisContainer, index) => {
					thisContainer.classList.remove('pr-cdb-form-group--error');
					clearError();

					const input = thisContainer.querySelector(
						'.pr-cdb-form-control'
					);
					const isRequired = input.getAttribute('required');
					if (isRequired) {
						if (input.type !== 'email') {
							if (!input.validity.valid) {
								formIsValid = false;
								earliestError =
									index < earliestError || !earliestError
										? index
										: earliestError;
								thisContainer.classList.add(
									'pr-cdb-form-group--error'
								);
							}
						} else if (!isEmailValid(input.value)) {
							formIsValid = false;
							earliestError =
								index < earliestError || !earliestError
									? index
									: earliestError;
							thisContainer.classList.add(
								'pr-cdb-form-group--error'
							);
						}
					}
				});

				if (earliestError !== null) {
					inputs[earliestError].focus();
					submitButton.style.display = 'none';
					nextButton.style.display = 'inline-block';

					messageContainer.classList.add('newsletter__message--show');
					fieldMessageContainer.classList.add(
						'newsletter__message--show'
					);
				}

				if (formIsValid) {
					const optIns = processOptIns(form);
					// then grab what's left of the form
					const formData = new FormData(form);

					// Build the data to send
					const jsonObject = {};

					// eslint-disable-next-line
					for (const [key, value] of formData.entries()) {
						jsonObject[
							key
								.replace('-welcome-mat', '')
								.replace('-subscribe', '')
						] = value;
					}

					jsonObject.optIns = optIns;
					jsonObject.birthday = `${
						formData.get('birthday[year]') ||
						formData.get('birthday[year]-welcome-mat') ||
						formData.get('birthday[year]-subscribe')
					}-${
						formData.get('birthday[month]') ||
						formData.get('birthday[month]-welcome-mat') ||
						formData.get('birthday[month]-subscribe')
					}-${
						formData.get('birthday[day]') ||
						formData.get('birthday[day]-welcome-mat') ||
						formData.get('birthday[day]-subscribe')
					}`;

					const request = new XMLHttpRequest();
					request.onreadystatechange = onRequestStateChange;
					// eslint-disable-next-line
					request.open('POST', PrCdb.api_endpoint);
					request.setRequestHeader(
						'Content-Type',
						'application/json;charset=UTF-8'
					);
					request.send(JSON.stringify(jsonObject));
				}
			};

			const isFormFilled = (thisInput) => {
				let filled = true;
				inputContainers.forEach((thisContainer) => {
					const input = thisContainer.querySelector(
						'.pr-cdb-form-control'
					);
					const isRequired = input.getAttribute('required');
					if (isRequired) {
						if (
							(!thisInput.validity.valueMissing &&
								!thisInput.validity.valid) ||
							(!thisInput.validity.valid &&
								(!thisInput.validity.rangeOverflow ||
									!thisInput.validity.rangeUnderflow))
						) {
							filled = false;
						} else {
							thisContainer.classList.remove(
								'pr-cdb-form-group--error'
							);
						}
					}
				});

				const lastInputContainer =
					inputContainers[inputContainers.length - 1];
				const lastInput = lastInputContainer.querySelector(
					'.pr-cdb-form-control'
				);

				return filled && thisInput === lastInput;
			};

			const continueForm = (nextIndex = null) => {
				let index = nextIndex;

				if (index === null) {
					if (
						inputs[currentInput] &&
						inputs[currentInput].type === 'checkbox' &&
						inputs[currentInput].required &&
						!inputs[currentInput].validity.valid
					) {
						index = currentInput;
					} else {
						index = currentInput === null ? 0 : currentInput + 1;
					}
				}

				let firstInvalidElement = null;

				clearError(inputs[index - 1]);

				if (inputs[index].type === 'email') {
					inputs[index].setCustomValidity(
						!isEmailValid(inputs[index].value)
							? 'Invalid Email'
							: ''
					);
				}

				const errorContainer = getErrorContainer(inputs[index]);

				if (
					(!inputs[index].validity.valueMissing &&
						!inputs[index].validity.valid) ||
					(!inputs[index].validity.valid &&
						(inputs[index].validity.rangeOverflow ||
							inputs[index].validity.rangeUnderflow)) ||
					(index === 0 && !inputs[index].validity.valid) ||
					(inputs[currentInput].type === 'checkbox' &&
						inputs[currentInput].required &&
						!inputs[currentInput].validity.valid)
				) {
					inputs[index].focus();
					showError(inputs[index], errorContainer);
				} else if (inputs[index]) {
					if (inputs[index].tagName === 'SELECT') {
						form.scroll({
							left: inputs[index].offsetLeft - form.offsetLeft,
							behavior: 'smooth'
						});

						setTimeout(() => {
							inputs[index].focus();
						}, 450);
					} else {
						inputs[index].focus();
					}
				} else {
					// else go to the first field that's not filled in
					inputs.forEach((input, invalidIndex) => {
						if (!input.validity.valid) {
							if (firstInvalidElement === null) {
								firstInvalidElement = invalidIndex;
							}

							showError(input, errorContainer);
						}
					});
				}

				if (firstInvalidElement !== null) {
					inputs[firstInvalidElement].focus();
				}
			};

			const keyDownListener = (event) => {
				if (event.keyCode === 13 || event.code === 'Tab') {
					event.preventDefault();

					if (!event.shiftKey) {
						const lastInput = inputs[inputs.length - 1];
						if (event.target === lastInput) {
							submitButton.focus();
						}

						continueForm();
					} else {
						continueForm(
							currentInput !== null && currentInput > 0
								? currentInput - 1
								: 0
						);
					}
				}
			};

			nextButton.addEventListener('click', () => {
				continueForm();
			});

			submitButton.addEventListener('click', () => {
				spinner.classList.add('active');
				submitForm();
			});

			// commenting resetButton as I think it might be asked for in the future
			// resetButton.addEventListener( 'click', () => {
			// 	clearForm();
			// } );

			backButton.addEventListener('click', () => {
				const index = currentInput === null ? 0 : currentInput - 1;
				if (inputs[index]) {
					form.scroll({
						left: inputs[index].offsetLeft - form.offsetLeft,
						behavior: 'smooth'
					});
					setTimeout(() => {
						inputs[index].focus();
					}, 200);
				}
			});

			inputContainers.forEach((thisContainer) => {
				thisContainer.addEventListener('click', () => {
					if (
						!thisContainer.classList.contains(
							'pr-cdb-form-group__dob'
						)
					) {
						const input = thisContainer.querySelector('input');
						if (input) {
							input.focus();
						}
					}
				});
			});

			inputs.forEach((input, index) => {
				input.addEventListener('focus', (event) => {
					event.preventDefault();
					let inputToUse = input;
					currentInput = index;
					let firstInvalidElement = null;

					clearError();

					// else go to the first field that's not filled in
					inputs.forEach((thisInput, invalidIndex) => {
						if (
							thisInput.validity.valueMissing ||
							thisInput.validity.rangeOverflow ||
							thisInput.validity.rangeUnderflow ||
							(thisInput.type === 'email' &&
								!isEmailValid(thisInput.value))
						) {
							if (firstInvalidElement === null) {
								firstInvalidElement = invalidIndex;
							}
						}
					});

					if (
						(firstInvalidElement === null ||
							firstInvalidElement >= currentInput) &&
						(inputs[firstInvalidElement] === input ||
							(input.type !== 'checkbox' && !!input.value) ||
							(firstInvalidElement === null &&
								input.type === 'checkbox'))
					) {
						if (
							input.classList.contains(
								'pr-cdb-form-control__month'
							) ||
							input.classList.contains(
								'pr-cdb-form-control__year'
							)
						) {
							inputToUse = Array.from(inputs).find((thisInput) =>
								thisInput.classList.contains(
									'pr-cdb-form-control__day'
								)
							);
						}

						if (index) {
							backButton.style.display = 'inline-block';
						} else {
							backButton.style.display = 'none';
						}

						// calculate whether it's Safari and next input is a terms checkbox
						// if it is, then move scroll 7px to the left as it was cutting off
						// the checkbox previously
						const isSafari = document
							.querySelector('html')
							.classList.contains('safari');
						const isTerms = inputToUse.type === 'checkbox';
						const extraMargin = isSafari && isTerms ? 7 : 0;

						form.scroll({
							left:
								inputToUse.offsetLeft -
								extraMargin -
								form.offsetLeft,
							behavior: 'smooth'
						});

						window.addEventListener('keydown', keyDownListener);

						const parentElement = getParentElement(inputToUse);

						parentElement.classList.add(
							'pr-cdb-form-group--active'
						);

						percentage = (index + 1) / inputs.length;
						indicator.style.width = `${
							percentage * indicatorContainer.offsetWidth
						}px`;
					} else if (firstInvalidElement !== null) {
						goBackToErrorField(firstInvalidElement);
					}

					if (isFormFilled(input)) {
						nextButton.style.display = 'none';
						submitButton.style.display = 'inline-block';
					} else {
						submitButton.style.display = 'none';
						nextButton.style.display = 'inline-block';
					}
				});

				input.addEventListener('blur', () => {
					window.removeEventListener('keydown', keyDownListener);

					const parentElement = getParentElement(input);

					parentElement.classList.remove('pr-cdb-form-group--active');
				});

				input.addEventListener('change', (thisInput) => {
					if (isFormFilled(thisInput.target)) {
						nextButton.style.display = 'none';
						submitButton.style.display = 'inline-block';
						messageContainer.classList.remove(
							'newsletter__message--show'
						);
						message.classList.remove('newsletter__message--show');
						fieldMessageContainer.classList.remove(
							'newsletter__message--show'
						);
						emailMessageContainer.classList.remove(
							'newsletter__message--show'
						);
						dateMessageContainer.classList.remove(
							'newsletter__message--show'
						);
						birthdayMessageContainer.classList.remove(
							'newsletter__message--show'
						);
					} else {
						submitButton.style.display = 'none';
						nextButton.style.display = 'inline-block';
					}
				});
			});

			window.addEventListener('resize', () => {
				indicator.style.width = `${
					percentage * indicatorContainer.offsetWidth
				}px`;
			});
		});
	} catch (error) {
		console.error(error);
	}
}
